import { Paper } from '@mui/material';

import { ChildrenProps } from 'core/dom/types';

export function Box({ children }: ChildrenProps) {
  return (
    <Paper
      variant="outlined"
      className=" flex flex-col justify-center items-center w-full max-w-screen-md px-6 md:px-10 py-6"
    >
      {children}
    </Paper>
  );
}
