import Head from 'next/head';

interface Props {
  pageTitle: string;
}
export const PageHead = ({ pageTitle }: Props) => (
  <Head>
    <title>Fractal | {pageTitle}</title>
    <meta
      name="description"
      content="Manage your Fractal games, launches, and integrations"
    />
    <link rel="icon" href="/favicon.png" />
  </Head>
);
