import { SignInWithGoogleButton } from '@fractalwagmi/fractal-auth-lib';
import { CircularProgress, Box as MaterialBox } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { AuthContext } from 'components/auth/context';
import { Box } from 'components/core/box';
import { FullScreen } from 'components/core/full-screen';
import { EmptyLayout } from 'components/layout/empty-layout';
import { PageHead } from 'components/nav/head';
import { LOGO_URL } from 'core/media/constants';
import { Router } from 'core/router';
import { NextPageWithLayout } from 'lib/nextjs';

const Home: NextPageWithLayout = () => {
  const { loading, user } = useContext(AuthContext);
  const router = useRouter();

  if (!loading && user) {
    router.replace(Router.GLOBAL);
  }

  return (
    <div>
      <PageHead pageTitle="Hot Tub" />
      <main>
        <FullScreen>
          <Box>
            <Image src={LOGO_URL} alt="" width="300" height="93" />
            <MaterialBox
              fontWeight="bold"
              mt={2}
              mb={6}
              className="text-xs md:text-lg"
            >
              Welcome to Hot Tub. Get ready to &#128640;
            </MaterialBox>
            {loading ? <CircularProgress /> : <SignInWithGoogleButton />}
          </Box>
        </FullScreen>
      </main>
    </div>
  );
};

export default Home;

Home.getLayout = page => {
  return <EmptyLayout>{page}</EmptyLayout>;
};
